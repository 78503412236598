import React, {useEffect, useState} from "react";
import {getFetcher} from "../../utils/fetcher";
import {DELETE_GAME_TICKET_API, UPDATE_GAME_TICKET_API} from "../../constants/api_constants";
import {accountInfo} from "../../constants/constants";
import {toast} from "react-toastify";
import RequestModal from "../../components/Modal/RequestModal";
import BaseTicketListPage from "./base/BaseTicketListPage";
import Sidebar from "../../components/Sidebar";

const MyGameCartPage = () => {
  const [isRequesting, setIsRequesting] = useState(false);
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const [selectTicket, setSelectTicket] = useState({ticket: []});


  useEffect(() => {
  }, [isRequesting]);

  function _onModalOpen(ticket, ticketJson) {
    onSelectedTickets(ticket, ticketJson);
    setRequestModalOpen(true);
  }

  function onSelectedTickets(selectedTickets, ticketJson) {
    setSelectTicket({ticket: selectedTickets, ticketJson:ticketJson});
  }

  function requestPrintMethod(params) {
    // 모달 입력값 받아서 출력의뢰에 데이터 넘기기
    let memo = params.requestMemo;

    if (params.visitTime !== null && params.visitTime.length > 0) {
      memo = '방문예정일시 : ' + params.visitTime + ') ' + memo;
    }

    let requestJSON = {
      user: accountInfo.makeJson(),
      myGameDataId: '',
      newAmount: -1,
      targetStoreCode: params.selectedStore.data.code,
      memo: memo,
      newStatus: 'order'
    };
    setIsRequesting(true);
    let ticketList = [];
    selectTicket.ticket.forEach((ticket) => {
      ticketList.push({id: ticket, newAmount: selectTicket.ticketJson[ticket].newAmount})
    });
    _requestPrintMethod(ticketList, requestJSON);
  }

  function _requestPrintMethod(ticketList, requestJSON) {
    // 출력의뢰한 티켓 하나씩 API 날리기
    let ticketData = ticketList.pop()
    requestJSON.myGameDataId = ticketData.id;
    requestJSON.newAmount = ticketData.newAmount;

    getFetcher().post(UPDATE_GAME_TICKET_API, requestJSON)
        .then(res => {
          const {type, message} = res.data.header;
          if (type === 'success') {
            toast.success(message);
          } else {
            toast.error(message);
          }
          if (ticketList.length > 0)
            _requestPrintMethod(ticketList, requestJSON);
          else {
            setIsRequesting(false);
          }
        }).catch(err => {
      toast.error(err.message);
      setIsRequesting(false);
    })
  }

  const deleteTicketCard = (ticket) => {
    setIsRequesting(true);
    // 체크한 티켓 삭제
    getFetcher().post(DELETE_GAME_TICKET_API, {
      user: accountInfo.makeJson(),
      gamedataIds: ticket
    }).then(res => {
          const {message, type} = res.data.header;
          if (type === 'success') {
            toast.success(message);
            setIsRequesting(false);
          } else {
            toast.error(message);
          }
        }
    ).catch(err => toast.error(err.message));
  }

  const actionsJson = [
    {name: '출력의뢰*', color: 'rose', onClick: _onModalOpen},
    {name: '체크내역삭제', color: 'gray', onClick: deleteTicketCard}
  ]
  const ticketState = 'cart';
  const ticketOrderType = {view: ["memo-user-invisible"], options: []};
  const fixedFilter = {searchCondition: []};
  const requestState = [];

  return (
      <>
        <Sidebar/>

        {requestModalOpen &&
            <RequestModal requestMethod={requestPrintMethod} requestModalData={{sent_ticket_list: selectTicket.ticket}}
                          setRequestModalOpen={setRequestModalOpen}/>
        }

        <BaseTicketListPage listKey="user_cart" isRequesting={isRequesting} isShowFilter={true} actionsJson={actionsJson}
                            ticketOrderType={ticketOrderType}
                            ticketState={ticketState} fixedFilter={fixedFilter} requestState={requestState}
        />
      </>
  )
}
export default MyGameCartPage;
import TicketCard from "./Card/TicketCard";
import neverLandGameData from "../utils/NeverLandGameData";
import React, {useEffect, useRef, useState} from "react";
import {ExclamationCircleIcon} from "@heroicons/react/24/solid";
import RequestModal from "./Modal/RequestModal";
import {MONEY_BUTTON_DATA, MONEY_BUTTON_DATA_SIMPLE} from "../constants/component_data";
import {RiCloseLine, RiSettings4Fill} from "react-icons/ri";
import neverLandUtils from "../utils/NeverLandUtils";
import {getFetcher} from "../utils/fetcher";
import {REGIST_NEW_GAME_TICKET_API} from "../constants/api_constants";
import {accountInfo} from "../constants/constants";
import {toast} from "react-toastify";
import {Link, useNavigate} from "react-router-dom";
import {
  MAIN_PAGE,
  MY_CART_PAGE,
  MY_SETTING_PAGE,
  REQUEST_LIST_PAGE,
  STORE_REQUEST_LIST_PAGE
} from "../constants/page_constants";


const CartSidebar = ({showCartSidebar, setShowCartSidebar}) => {
  const [amountOnChange, setAmountOnChange] = useState(false);
  const [batchPrice, setBatchPrice] = useState(0);
  const [requestModalOpen, setRequestModalOpen] = useState({modalOpen: false});
  const [isSimpleMode, setIsSimpleMode] = useState(accountInfo.getUserConfig().isOn('use_simple_mode', true));

  const navigator=useNavigate();
  const [index, setIndex] = useState(0);

  const [isPoweredEventObject, setIsPoweredEventObject] = useState({
    // 우선순위 부여
    isPoweredRequest: false,
    disablePowered: () => {
      isPoweredEventObject.isPoweredRequest = false;
    },
  });

  function disposeTicket(sent_ticket_list) {
    // 카트사이드바에서 티켓 지우기
    for (let t of neverLandGameData.ticketData) {
      if (sent_ticket_list.includes(t.id)) {
        t.selectGame = [];
      }
    }
    updateUI();
  }

  const updateUI = () => {
    setIndex(prevState => prevState + 1);
  }
  const handleOnChanged = () => {
    updateUI();
  };

  useEffect(() => {
    neverLandGameData.addOnChangedHandler(handleOnChanged);
    return () => {
      neverLandGameData.removeOnChangedHandler(handleOnChanged);
    }
  }, []);

  let selected_ticket_data = null;
  const selectTicketDataList = (list) => {
    selected_ticket_data = list;
  }

  function _refreshSelectedTicket() {
    neverLandGameData.clearSelection();
  }

  const registNewGameTickets = (type) => {
    // type = 'cart': 카트담기, 'print' : 출력의뢰
    if (selected_ticket_data === null || selected_ticket_data.length === 0) {
      toast.warning("선택한 티켓이 없습니다.");
      return;
    }

    let user = accountInfo.makeJson();
    let postJson = {
      user: user,
      list: [],
      targetStoreCode: '',
      defaultStatus: 'cart'
    };

    let ticket_data;
    let sent_ticket_list = [];

    for (let ticket of selected_ticket_data) {
      if (ticket.amount === 0)
        continue;
      ticket_data = {
        gameAllotCodesAndSelection: [],
        amount: ticket.amount,
        unitType: 'won',
        memo: '',
        ticketCount: ticket.ticketCount
      };
      sent_ticket_list.push(ticket.id);
      for (let game of ticket.selectedGame) {
        ticket_data.gameAllotCodesAndSelection.push({
          allotId: game.allotId, gameNo: game.gameNumber, selection: game.prediction
        });
      }
      postJson.list.push(ticket_data);
    }
    if (selected_ticket_data.length !== postJson.list.length) {
      toast.warning("금액을 입력해 주세요.");
      return;
    }
    if (type === 'print') {
      // 출력 의뢰 - 모달 띄우기
      setRequestModalOpen({modalOpen: true, postJson: postJson, sent_ticket_list: sent_ticket_list});
      return;
    }
    if (type === 'cart') {
      // 카트 담기
      getFetcher().post(REGIST_NEW_GAME_TICKET_API, postJson)
          .then(res => {
            const {type, message} = res.data.header;
            if (type === 'success') {
              disposeTicket(sent_ticket_list);
              toast.success(message);
              if( accountInfo.getUserConfig().isOn('on_refresh_select_game', true)){
                _refreshSelectedTicket();
              }
              if( accountInfo.getUserConfig().isOn('on_route_page', true)){
                navigator(MY_CART_PAGE);
              }
            } else {
              toast.error(message);
            }
          }).catch(err => {
        toast.error(err.message);
      })
      return;
    }
  }

  function requestPrintMethod(params) {
    // 출력의뢰
    let postJson = requestModalOpen.postJson;
    let sent_ticket_list = requestModalOpen.sent_ticket_list;

    postJson.defaultStatus = 'order';
    postJson.targetStoreCode = encodeURIComponent(params.selectedStore.data.code);
    postJson.customerUserIdForSpecialOrder = params.customerUserIdForSpecialOrder;

    let memo = '방문예정일시 : ' + params.visitTime + ') ' + params.requestMemo;
    for (let allot of postJson.list) {
      allot.memo = memo;
    }
    getFetcher().post(REGIST_NEW_GAME_TICKET_API, postJson)
        .then(res => {
          const {type, message} = res.data.header;
          if (type === 'success') {
            disposeTicket(sent_ticket_list);
            toast.success(message);
            if( accountInfo.getUserConfig().isOn('on_refresh_select_game', true)){
              _refreshSelectedTicket();
            }
            if( accountInfo.getUserConfig().isOn('on_route_page', true)){
              if(accountInfo.isStore()){
                navigator(STORE_REQUEST_LIST_PAGE);
              }
              else{
                navigator(REQUEST_LIST_PAGE);
              }
            }
          } else {
            toast.error(message);
          }
        }).catch(err => {
      toast.error(err.message);
    })
    return;
  }

  let currentFolderNumber = neverLandGameData.currentFolderNumber();

  function selectAllTickets() {
    // 티켓 전체선택
    let _selected_ticket_data = [];
    for (let t of neverLandGameData.ticketData) {
      _selected_ticket_data.push({
        amount: t.currentAmount,
        selectedGame: t.selectGame,
        id: t.id
      });
    }
    selected_ticket_data = _selected_ticket_data;
  }

  function _setIsSimpleMode(isSimpleMode) {
    // 일반모드 / 간편모드
    accountInfo.getUserConfig().setOptionOn('use_simple_mode', isSimpleMode);
    accountInfo.storeUserConfig();
    setIsSimpleMode(isSimpleMode);
  }

  return (
      <>
        {requestModalOpen.modalOpen &&
            <RequestModal requestMethod={requestPrintMethod} requestModalData={requestModalOpen}
                          setRequestModalOpen={setRequestModalOpen}/>
        }

        <div className="sm:mt-20 lg:mt-0">
          <div className="justify-between flex mb-3">
            <div className="flex flex-row justify-center ring-2 ring-gray-900 rounded-lg">
              <button
                  onClick={() => _setIsSimpleMode(false)}
                  type="button"
                  className={neverLandUtils.classNames(isSimpleMode ? "bg-gray-100 text-gray-900" : "bg-gray-900 text-white", "whitespace-nowrap rounded-lg px-1.5 py-1 text-xs font-semibold shadow-sm")}
              >
                일반모드
              </button>
              <button
                  onClick={() => _setIsSimpleMode(true)}
                  type="button"
                  className={neverLandUtils.classNames(isSimpleMode ? "bg-gray-900 text-white" : "bg-gray-100 text-gray-900", "whitespace-nowrap rounded-lg px-1.5 py-1 text-xs font-semibold shadow-sm")}
              >
                간편모드
              </button>
            </div>

            <div className="flex flex-row items-center">
              <Link to={MY_SETTING_PAGE}>
                <div className="flex flex-row items-center text-gray-500 mr-2 sm:mr-0">
                  <p>설정</p>
                  <RiSettings4Fill className="h-5 w-auto"/>
                </div>
              </Link>
              {showCartSidebar &&
                  <button className="inline-block" onClick={() => setShowCartSidebar(false)}>
                    <RiCloseLine className="h-7 w-7 text-black"/>
                  </button>
              }
            </div>
          </div>

          {!isSimpleMode &&
              <div className="flex flex-col justify-between">
                <div className="flex flex-row">
                  <button
                      onClick={() => {
                        selectAllTickets();
                        registNewGameTickets('cart');
                      }}
                      type="button"
                      className="whitespace-nowrap mr-5 rounded-md bg-sky-600 px-10 md:px-14 py-2 text-sm font-semibold text-white shadow-sm hover:bg-sky-500"
                  >
                    전체담기
                  </button>
                  <button
                      onClick={() => {
                        selectAllTickets();
                        registNewGameTickets('print');
                      }}
                      type="button"
                      className="whitespace-nowrap rounded-md bg-rose-600 px-10 md:px-14 py-2 text-sm font-semibold text-white shadow-sm hover:bg-rose-500"
                  >
                    전체의뢰
                  </button>
                </div>


                <div className="flex flex-row mt-2">
                  <button
                      onClick={() => registNewGameTickets('cart')}
                      type="button"
                      className="whitespace-nowrap mr-5 rounded-md bg-teal-500 px-10 md:px-14 py-2 text-sm font-semibold text-white shadow-sm hover:bg-teal-400"
                  >
                    카트담기
                  </button>
                  <button
                      onClick={() => registNewGameTickets('print')}
                      type="button"
                      className="whitespace-nowrap rounded-md bg-amber-500 px-10 md:px-14 py-2 text-sm font-semibold text-white shadow-sm hover:bg-amber-400"
                  >
                    출력의뢰
                  </button>
                </div>
              </div>
          }
        </div>

        <div className="flex flex-row justify-start mt-3 flex-wrap">
          {
            neverLandGameData.folderList().map((folderNumber) => {
              return (
                  <button
                      type="button"
                      className={neverLandUtils.classNames(currentFolderNumber === folderNumber ? "bg-gray-900 text-white ring-gray-900" : "bg-white text-gray-900 ring-slate-300",
                          "mt-1 px-3 py-2 mr-2 rounded text-sm font-semibold shadow-sm ring-1 ring-inset hover:ring-slate-400 hover:bg-slate-400 hover:text-white"
                      )}
                      onClick={() => {
                        isPoweredEventObject.isPoweredRequest = true;
                        neverLandGameData.refreshFolder(folderNumber);
                      }}
                  >
                    {folderNumber}폴더
                  </button>
              )
            })
          }
        </div>

        <div className="flex flex-row justify-between items-center mt-3">
          <p className="text-md sm:text-lg font-semibold">금액 일괄입력</p>
          <div
              className="flex flex-row rounded-md bg-white border-0 ring-1 ring-inset ring-gray-300 py-0.5 pl-3 ml-4">
            <input
                className="text-left w-28 py-1 font-semibold sm:leading-6 focus:ring-0 focus:ring-white mr-2"
                value={(amountOnChange ? batchPrice : batchPrice.toLocaleString())}
                onFocus={(e) => {
                  e.target.value = batchPrice;
                  setAmountOnChange(true)
                  // 커서 제일 끝으로 보내기
                  setTimeout(() => {
                    const length = e.target.value.length;
                    e.target.setSelectionRange(length, length);
                  }, 0);
                }}
                onBlur={(e) => {
                  e.target.value = batchPrice.toLocaleString();
                  setAmountOnChange(false)
                }}
                onChange={(e) => {
                  isPoweredEventObject.isPoweredRequest = true;
                  let newValue = neverLandUtils.utils.textToNumber(e.target.value);
                  setBatchPrice(newValue);
                }}
            />
            <button type="reset">
              <RiCloseLine className="h-4 w-auto px-1" onClick={() => {
                isPoweredEventObject.isPoweredRequest = true;
                setBatchPrice(0);
              }}/>
            </button>
          </div>
          <p className="text-lg font-semibold">원</p>
        </div>

        <div className="flex flex-row justify-between mt-5">
          {(isSimpleMode ? MONEY_BUTTON_DATA_SIMPLE : MONEY_BUTTON_DATA).map((data) =>
              <button
                  className="rounded bg-white px-9 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-slate-300 whitespace-nowrap"
                  key={data.value}
                  onClick={() => {
                    isPoweredEventObject.isPoweredRequest = true;
                    setBatchPrice((prevPrice) => Number(prevPrice + data.value));
                  }}
                  value={data.value}
                  type="button"
              >
                {data.name}
              </button>
          )}
        </div>

        {isSimpleMode &&
            <div className="flex justify-center">
              <button
                  onClick={() => {
                    selectAllTickets();
                    registNewGameTickets('print');
                  }}
                  type="button"
                  className="mt-3 whitespace-nowrap rounded-md bg-rose-600 px-10 md:px-14 py-1 text-sm font-semibold text-white shadow-sm hover:bg-rose-500"
              >
                의뢰하기
              </button>
            </div>
        }

        {/*티켓카드영역*/}
        <div className="flex flex-col">
          <>
            {
              neverLandGameData._selectedItems.length > 0 ? (
                  neverLandGameData.ticketData.length === 0 ? (
                      <div className="flex flex-col justify-center mt-5 items-center">
                        <ExclamationCircleIcon className="h-10 w-10 text-blue-800 mr-5"/>
                        <p className="text-blue-800 font-semibold text-lg">
                          ※ 잘못된 조합입니다. <br/>
                          아래와 같은 상황인지 확인해주세요. <br/><br/>
                          1. 싱글이외의 경기를 하나만 선택 <br/>
                          2. 같은 리그내의 경기만 선택 <br/>
                          3. 같은 리그내의 경기를 여러개 고정 <br/>
                        </p>
                      </div>
                  ) : (<TicketCard batchPrice={batchPrice} isPoweredObject={isPoweredEventObject}
                                   selectTicketDataList={selectTicketDataList}
                                   ticketType={{isSimpleMode: isSimpleMode, selection: !isSimpleMode}}/>
                  )
              ) : (
                  <div className="flex flex-col justify-center mt-5 items-center">
                    <ExclamationCircleIcon className="h-10 w-10 text-blue-800 mr-5"/>
                    <p className="text-blue-800 font-semibold text-lg">
                      선택된 경기가 없습니다. <br/>
                      경기를 선택해 주세요.
                    </p>
                  </div>
              )
            }
          </>
        </div>
      </>
  );
}
export default CartSidebar;